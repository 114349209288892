<template>
  <div class="container">
    <div class="pt-3 pb-5" v-if="assessment">
      <IntroCard />
    </div>
    <div class="flex flex-row justify-center pb-5">
      <img src="@/assets/img/logo-grey.svg" width="130" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import IntroCard from "../components/IntroCard";
import scrollToTop from "../../../services/utils/scrollToTop";

export default {
  name: "AssessmentView",
  components: {
    IntroCard,
  },
  computed: {
    ...mapState({
      assessment: (state) => state.assessments.selectedAssessment,
    }),
  },
  methods: {
    ...mapActions({
      getSurvey: "assessments/getSurvey",
    }),
  },
  mounted() {
    if (!this.assessment) {
      this.getSurvey({ id: this.$route.params.id });
    }
    scrollToTop();
  },
};
</script>
