<template>
  <div class="w-l mx-auto rounded shadow bg-white" v-cloak>
    <div class="relative" v-if="isOngoing">
      <img :src="introImage" rel="preload" class="w-full"/>
      <div
        class="absolute z-20 bg-ring w-full h-full overlay bg-center bg-no-repeat bg-auto"
      >
        <div class="assessment-current h-50 w-150 text-center">
          <h1 class="text-xxl font-headers font-semibold text-grey-light">
            <span class="text-blue-navy">
              {{ assessment.currentQuestion - 1 }}
            </span>
            / {{ assessment.totalQuestions }}
          </h1>
          <p class="text-blue-navy text-sm-2 w-100 text-center mx-auto pt-1">
            Questions completed
          </p>
        </div>
      </div>
      <div
        class="absolute w-a-full h-4 bg-grey-dark-3 rounded mx-auto progress-bar z-20"
      >
        <p class="text-white text-sm absolute complete-text">
          <span class="font-bold">{{ completed }}</span> Complete
        </p>
        <div
          class="h-4 bg-white rounded w-full transition-all"
          :style="{ width: completed }"
        ></div>
      </div>
      <div
        class="absolute bg-blue-navy w-full h-full opacity-50 overlay z-10"
      />
    </div>
    <div class="relative" v-else-if="hasExpired">
      <img :src="introImage" rel="preload" class="w-full" />
      <div
        class="assessment-current flex h-180 w-180 bg-grey-dark-1 rounded-full opacity-70 justify-center items-center z-20"
      >
        <h1 class="text-xxl font-headers font-semibold text-white">
          Expired
        </h1>
      </div>
      <div
        class="absolute bg-grey-dark-1 w-full h-full opacity-40 overlay z-10"
      />
    </div>
    <div class="relative" v-else-if="isClosed">
      <img :src="introImage" rel="preload" class="w-full" />
      <div
        class="assessment-current flex h-180 w-180 bg-grey-dark-1 rounded-full opacity-70 justify-center items-center z-20"
      >
        <h1 class="text-xxl font-headers font-semibold text-white">
          Closed
        </h1>
      </div>
      <div
        class="absolute bg-grey-dark-1 w-full h-full opacity-40 overlay z-10"
      />
    </div>
    <img :src="introImage" rel="preload" class="w-full" v-else />
    <div class="p-4">
      <h3 class="font-headers font-bold text-l text-grey-dark-2 mb-4">
        {{ hasExpired || isClosed ? "Sorry" : "Hello" }} {{ firstName }},
      </h3>
      <RichText :content="textToShow" :editable="false" />
      <Button
        class="w-full mt-7 justify-center"
        :type="isClosed || hasExpired ? 'secondary' : 'primary'"
        size="medium"
        :text="buttonText"
        @click.native="buttonClick"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import moment from "moment";
import landingImage from "@/assets/img/assessment/intro-image.png";
import RichText from "@/components/RichText/TipTap.vue";

import { downloadSurveyImage } from "../../../services/api/products";

export default {
  name: "IntroCard",
  components: { RichText },
  data: () => ({
    landingImage,
    introImage: null,
  }),
  computed: {
    ...mapState({
      firstName: (state) => state.home.firstName,
      assessment: (state) => state.assessments.selectedAssessment,
      assessments: (state) => state.assessments.assessments,
    }),
    ...mapGetters({
      getIntroText: "assessments/getAssessmentIntro",
      getClosedText: "assessments/getAssessmentClosed",
      getAssessment: "assessments/getAssessmentById",
      getCompletion: "assessments/getSelectedAssessmentCompletion",
    }),
    completed() {
      return this.getCompletion().remaining;
    },
    introImageId() {
      return this.assessment?.introImageId;
    },
    closedImageId() {
      return this.assessment?.closedImageId;
    },
    isClosed() {
      const selectedAssessment = this.assessments.find(
        (item) => item.id === this.assessment.id
      );
      return selectedAssessment?.isClosed;
    },
    hasExpired() {
      const selectedAssessment = this.assessments.find(
        (item) => item.id === this.assessment.id
      );
      return selectedAssessment?.hasExpired;
    },
    textToShow() {
      const showIntroText = !this.hasExpired && !this.isClosed;
      const text = showIntroText ? this.getIntroText : this.getClosedText;
      try {
        JSON.parse(text);
      } catch (err) {
        return text;
      }
      return JSON.parse(text);
   },
    isOngoing() {
      return this.assessment.inProgress && !this.isClosed;
    },
    buttonText() {
      if (this.hasExpired || this.isClosed) return "Go to Dashboard";
      return "Next";
    },
  },
  methods: {
    ...mapActions({
      startSurvey: "assessments/startSurvey",
    }),
    goToDashboard() {
      this.$router.push(`/`);
    },
    async beginSurvey() {
      await this.startSurvey({ id: this.assessment.id });
      this.$router.push(`/assessment/${this.assessment.id}/survey`);
    },
    buttonClick() {
      if (this.hasExpired) {
        return this.goToDashboard();
      }
      return this.isClosed ? this.goToDashboard() : this.beginSurvey();
    },
    createFinalDate() {
      const firstDate = new Date(this.assessment.launchedOn);
      if (this.assessment.timeAllocation) {
        const [hours, minutes, seconds] = this.assessment.timeAllocation.split(":").map(Number);
        return moment(new Date(firstDate.getTime() + hours * 3600000 + minutes * 60000 + seconds * 1000)).format("ll");
      }
    },
  },
  async mounted() {
    this.introImage = this.landingImage;
    if(this.hasExpired || this.isClosed) {
      if(this.closedImageId) {
        const rsp = await downloadSurveyImage(this.closedImageId);
        this.introImage = URL.createObjectURL(rsp.data);
      }
    } else if (this.introImageId) {
      const rsp = await downloadSurveyImage(this.introImageId);
      this.introImage = URL.createObjectURL(rsp.data);
    } 
  },
};
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.complete-text {
  right: 0;
  top: -20px;
}
.overlay {
  top: 0; // HACK: tailwind inset not working
}
.assessment-current {
  left: 50%;
  right: 50%;
  position: absolute;
  top: 48%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.progress-bar {
  bottom: 12px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
}
</style>
